$product-lineitem-highlight-title-font: normal #{rem-calc(14)} / #{rem-calc(21)} $font-primary;
$product-lineitem-highlight-title-text-transform: lowercase;
$product-lineitem-highlight-action-font: normal #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$product-lineitem-highlight-label-font: 300 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$product-lineitem-highlight-action-text-transform: lowercase;
$product-lineitem-highlight-label-color: color(text-secondary);
$product-lineitem-highlight-value-font: normal #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$product-lineitem-highlight-value-color: color(primary);
$product-lineitem-highlight-value-text-transform: initial;
$product-lineitem-highlight-label-text-transform: lowercase;
$product-lineitem-highlight-title-padding: rem-calc(0 0 10 0);
$product-lineitem-highlight-title-border-bottom: 1px solid color(border);

@import "@lora/05-components/product/product-lineitem-highlight";