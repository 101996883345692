// Button
$header-hamburger-button-background: color(dark);
$header-hamburger-button-active-padding: rem-calc(9 16 7 6);
$header-hamburger-button-active-border: solid color(light);
$header-hamburger-button-active-border-width: 0;
$header-hamburger-button-active-color: color(light);
// Button icon
$header-hamburger-button-icon-height: rem-calc(20);
$header-hamburger-button-icon-width: rem-calc(20);
// Item link
// Icons
$header-hamburger-icon-color: color(light);
$header-hamburger-item-icon-color: color(primary);

// Icons
$header-hamburger-item-color: color(primary);
$header-hamburger-icon-margin: rem-calc(0 10 0 0);
$header-hamburger-icon-height: rem-calc(35);
$header-hamburger-icon-width: rem-calc(35);
$header-hamburger-account-menu-icon-size: rem-calc(35 35);
$header-hamburger-storeslink-icon-size: rem-calc(35 35);
$header-hamburger-asset-link-may-we-help-you-icon: menu_questionmark;
$header-hamburger-asset-link-may-we-help-you-icon-size: rem-calc(35 35);
$header-hamburger-asset-link-why-shop-online-icon-size: rem-calc(35 35);
// localization
$header-hamburger-localization-button-padding: rem-calc(15.5 40 15.5 16);
$header-hamburger-item-link-padding: rem-calc(11 0 12 0);
$header-hamburger-localization-button-icon-size: rem-calc(16);
$header-hamburger-content-alignment--large: center;

@import "@lora/05-components/header/header-hamburger";
