$form-select-font-sizes: (
    "small": rem-calc(10),
    "normal": rem-calc(12),
    "large": rem-calc(12)
);

$form-select-size: (
    "small": rem-calc(25),
    "normal": $global-input-primary-height,
    "large": rem-calc(50)
);

$form-select-border-color: color(border);
$form-select-border-color-active: color(element-background);
$form-select-text-transform: lowercase;
$form-select-arrow-color: color(primary);
$form-select-box-shadow-hover: none;
$form-select-float-empty-color: color(text);
$form-select-font-weight-hover: normal;
$form-select-panel-item-text-transform: lowercase;
$form-select-icon-border-radius: 0;
$form-select-font-size: rem-calc(12);
$form-select-font-family: $font-primary-light;

// Custom select
$form-select-panel-item-background-selected: color(light);
$form-select-panel-item-background-hover: color(light);
$form-select-panel-item-color-hover: color(primary-active);
$form-select-panel-item-font-selected: normal #{rem-calc(12)} / #{rem-calc(16)} $font-tertiary;
$form-select-panel-item-color-selected: color(primary-active);
$form-select-panel-item-font: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary-light;
$form-select-panel-item-padding: rem-calc(10 10 10 25);
$form-select-panel-border-color: color(element-background);

//Third
$form-select-third-background: color(light);
$form-select-third-border-color: color(border);
$form-select-third-color: color(dark);
$form-select-label-float-margin: 0 0 0 rem-calc(20);

$form-select-alt-padding: rem-calc(15 30 10 19);

// Single value
$form-select-single-value-label-float-left: rem-calc(-20);

// Product badge title in variations
$form-select-badge-text-transform: uppercase;

@import "@lora/05-components/form/select";