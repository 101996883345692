$font-primary: "HelveticaNeueLTStd", sans-serif;
$font-secondary: "HelveticaNeueLTStd-Ex", sans-serif;
$font-primary-light: "HelveticaNeueLTStd-Lt", sans-serif;
$font-tertiary: "HelveticaNeueLTStd-Roman", sans-serif;

$font-list: (
    ("HelveticaNeueLTStd", "HelveticaNeueLTStd-LtEx", 200),
    ("HelveticaNeueLTStd-Lt", "HelveticaNeueLTStd-Lt", 300, normal),
    ("HelveticaNeueLTStd-Roman", "HelveticaNeueLTStd-Roman", normal),
    ("HelveticaNeueLTStd-Ex", "HelveticaNeueLTStd-Ex", normal)
);

@import "@lora/font-settings";