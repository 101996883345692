$product-bundle-title-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$product-bundle-title-font--large: $product-bundle-title-font;
$product-bundle-title-text-transform: lowercase;
$product-bundle-title-border-bottom: none;
$product-bundle-title-name-font--medium-down: normal #{rem-calc(14)} / 1.45 $font-primary;

$product-bundle-item-padding: rem-calc(10 0 20);
$product-bundle-item-padding--large: rem-calc(25 0 20);

$product-bundle-title-qty-font--large: normal #{rem-calc(14)} / 1.4 $font-primary;
$product-bundle-title-qty-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$product-bundle-title-qty-color: color(dark);

@import "@lora/05-components/product/product-bundle";