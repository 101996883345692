/// Title
$content-tile-title-text-transform: lowercase;
$content-tile-title-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$content-tile-title-font--large: $content-tile-title-font;
$content-tile-title-margin: rem-calc(5 0 18);
$content-tile-title-margin--large: rem-calc(5 0 18);

/// Title Large
$content-tile-large-title-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$content-tile-large-title-font--large: $content-tile-large-title-font;
$content-tile-large-title-text-transform: lowercase;
$content-tile-large-title-text-transform--large: $content-tile-large-title-text-transform;
$content-tile-large-title-margin: rem-calc(0 0 16);
$content-tile-large-title-margin--large: $content-tile-large-title-margin;

/// Description
$content-tile-description-font: 300 #{rem-calc(14)} / 1.45 $font-primary;
$content-tile-description-font--large: $content-tile-description-font;
$content-tile-description-color: color(text-secondary);
$content-tile-description-text-transform: lowercase;

/// Description Large
$content-tile-large-description-font: 300 #{rem-calc(14)} / 1.45 $font-primary;
$content-tile-large-description-color: color(text-secondary);
$content-tile-large-description-text-transform: lowercase;
$content-tile-large-description-margin: rem-calc(0 0 35);

/// Label Large
$content-tile-large-label-font: normal #{rem-calc(12)} / 1.4 $font-primary;
$content-tile-large-label-font--large: $content-tile-large-label-font;
$content-tile-large-label-text-transform: lowercase;
$content-tile-large-label-text-transform--large: $content-tile-large-label-text-transform;
$content-tile-large-label-padding: rem-calc(7 0 8 25);
$content-tile-large-label-margin: rem-calc(0 0 17);
$content-tile-large-label-margin--large: $content-tile-large-label-margin;
$content-tile-large-label-border-left: 4px solid color(primary-active);
$content-tile-large-label-box-shadow: rem-calc(2 3 6 0) rgba(0, 0, 0, 0.1);
$content-tile-large-label-max-width: rem-calc(145);

$content-tile-label-font: normal #{rem-calc(12)} / 1.4 $font-primary;
$content-tile-label-text-transform: lowercase;
$content-tile-label-color: color(primary-active);
$content-tile-label-border: solid;
$content-tile-label-border-width: 0 0 0 1px;
$content-tile-label-border-width--large: $content-tile-label-border-width;
$content-tile-label-padding: rem-calc(0 0 0 5);
$content-tile-label-padding--large: $content-tile-label-padding;

// CTA
$content-tile-actions-margin: rem-calc(25 0 15);
$content-tile-actions-margin--large: rem-calc(25 0 0);

@import "@lora/05-components/content-tile";