@import "@root/05-components/storeslink";

$storeslink-icon-height: rem-calc(18px);
$storeslink-icon-width: rem-calc(13px);
$storeslink-icon-height--large: $storeslink-icon-height;
$storeslink-icon-width--large: $storeslink-icon-width;

.c-hamburger__item.m-stores {
    .c-storeslink {
        &::before {
            content: none;
        }
    }
}