$form-radius: 0;
$form-legend-font-size: rem-calc(10);
$form-legend-font-weight: 300;
$form-row-title-text-transform: lowercase;
$form-row-title-font-size: rem-calc(14);
$form-row-title-font-weight: 300;
$form-row-title-color: color(text-secondary);
$form-spacing-bottom: rem-calc(22);
$form-error-message-text-transform: lowercase;
$form-field-margin: rem-calc(0 0 20);

@import "@lora/05-components/form/form";