@import "@lora/03-base/colors";

$palette-default: (
    light:     #fff, // White
    dark:      #000,// Black
    transparent: transparent,
);

$dark-mode-colors: (
    background:  #000,
    text:        #fff,
    border:      #fff,
);

$palette: map-extend($palette-default, $palette-custom);

$semantic-colors-custom: (
    primary:             #000,
    primary-active:      #b80220,
    secondary:           #fff,
    secondary-active:    #2f2f2f,
    text:                color(dark),
    text-secondary:      #4e4e4e,
    success:             #276027,
    alert:               #ab000d,
    disabled:            #ccc,
    element-background:  #ccc,
    global-background:   #f7f6f7,
    organism-background: #333,
    border:              #d8d8d8,
    highlight:           #b80220
);

$semantic-colors: map-extend($semantic-colors-default, $semantic-colors-custom);