// Category
$header-navigation-flyout-category-title-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$header-navigation-flyout-category-title-text-transform: lowercase;
$header-navigation-flyout-category-link-font: 300 #{rem-calc(12)} / 1.5 $font-primary;
$header-navigation-flyout-category-link-text-decoration: none;
$header-navigation-flyout-category-link-color: color(text-secondary);
$header-navigation-flyout-category-list-item-margin: rem-calc(5 0);
$header-navigation-flyout-category-link-text-transform: lowercase;

// Push
$header-navigation-flyout-push-title-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$header-navigation-flyout-push-title-text-transform: lowercase;
$header-navigation-flyout-push-description-font: 300 #{rem-calc(14)} / 1.45 $font-primary;
$header-navigation-flyout-push-description-color: color(text-secondary);
$header-navigation-flyout-push-description-text-transform: lowercase;
$header-navigation-flyout-push-cta-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$header-navigation-flyout-push-cta-text-transform: lowercase;

@import "@lora/05-components/header/header-navigation-flyout";