// 1st type of a promo message
$promotion-product-font: normal #{rem-calc(12)} / 1.3 $font-primary;
$promotion-product-background: color(light);
$promotion-product-border: 1px solid color(disabled);
$promotion-product-tooltip-text-transform: lowercase;
$promotion-product-text-transform: lowercase;
$promotion-product-color: color(dark);

// 2nd type of a promo message
$promotion-approaching-discount-background: color(global-background);
$promotion-approaching-discount-font: 300 #{rem-calc(12)} / 1.4 $font-primary;
$promotion-approaching-discount-text-transform: lowercase;
$promotion-icon-color: color(success);
$promotion-with-icon-transform: lowercase;
$promotion-with-icon-font: normal #{rem-calc(12)} / 1.4 $font-primary;

@import "@lora/05-components/promotion";