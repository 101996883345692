$header-simple-search-label-font-family: $font-secondary;
$header-simple-search-field-text-transform: lowercase;
$header-simple-search-label-text-transform: lowercase;
$header-simple-search-label-color: color(text);
$header-simple-search-label-font-size: rem-calc(26);
$header-simple-search-field-placeholder-color: color(text);
$header-simple-search-field-placeholder-color--large: color(text);
$header-simple-search-field-height--large: rem-calc(80);
$header-simple-search-field-font-size: rem-calc(12);
$header-simple-search-field-font-size--large: rem-calc(26);
$header-simple-search-field-padding--large: rem-calc(0 10 0 0);
$header-simple-search-button-icon-color: color(dark);
$header-simple-search-button-height--large: rem-calc(20);
$header-simple-search-button-width--large: rem-calc(20);

@import "@lora/05-components/header/header-simple-search";