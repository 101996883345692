$account-table-cell-header-text-transform: lowercase;
$account-table-cell-header-font: normal #{rem-calc(12)} / #{rem-calc(16)} $font-primary-light;
$account-table-cell-header-font--large: 300 #{rem-calc(14)} / #{rem-calc(20)} $font-primary-light;
$account-table-cell-header-date-font--large: normal #{rem-calc(14)} / 1.45 $font-primary-light;
$account-table-cell-header-background: color(organism-background);
$account-table-cell-header-color: color(secondary);
$account-table-cell-header-sort-padding: rem-calc(3 14);
$account-table-cell-padding: rem-calc(3 14);
$account-table-cell-padding--large: $account-table-cell-padding;
$account-table-cell-color: color(text-secondary);
$account-table-cell-font: 300 #{rem-calc(10)} / #{rem-calc(16)} $font-primary-light;
$account-table-cell-font--large: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary-light;
$account-table-cell-status-font: $account-table-cell-font;
$account-table-cell-status-font--large: $account-table-cell-font--large;
$account-table-link-details-icon: chevron2-right;
$account-table-link-details-icon-height: rem-calc(10);
$account-table-link-details-icon-width: rem-calc(4);
$account-table-cell-text-transform: lowercase;
$account-table-cell-header-padding: rem-calc(26 30);
$account-table-cell-header-padding--large: rem-calc(35 14);
$account-table-cell-background--large: color(secondary);
$account-table-row-first-child-border-top: none;
$account-table-cell-product-font: 300 #{rem-calc(10)} / 1.5 $font-primary;
$account-table-cell-product-font--large: normal #{rem-calc(12)} / 1.4 $font-primary;
$account-table-cell-product-color: color(dark);

// Order icon
$account-table-cell-header-sort-icon: chevron-down;
$account-table-cell-header-sort-icon-height: rem-calc(15);
$account-table-cell-header-sort-icon-width: rem-calc(15);

/// Current
$account-table-cell-header-sort-current-icon-color: color(dark);
$account-table-cell-header-sort-current-icon-background-color: color(light);

//Hover state
$account-table-cell-background-hover: color(primary-active);
$account-table-cell-color-hover: color(light);
$account-table-row-box-shadow-hover: none;
$account-table-link-details-hover-color: color(light);

@import "@lora/05-components/account/account-table";