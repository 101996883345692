$footer-contacts-title-font: normal #{rem-calc(14)} / 1.25 $font-primary;
$footer-contacts-title-font--large: normal #{rem-calc(14)} / 1.25 $font-primary;
$footer-contacts-title-margin: rem-calc(0 0 17);
$footer-contacts-title-margin--large: rem-calc(0 0 18);
$footer-contacts-description-font: 300 #{rem-calc(14)} / 1.25 $font-primary;
$footer-contacts-title-text-transform: lowercase;
$footer-contacts-title-text-transform--large: $footer-contacts-title-text-transform;
$footer-contacts-text-align--medium-down: left;
$footer-contacts-description-flex-direction--medium-down: column;
$footer-contacts-link-first-child-margin: rem-calc(0 0 10 0);
$footer-contacts-link-padding: rem-calc(6 20 7 13);
$footer-contacts-link-border: 1px solid color(border);
$footer-contacts-link-color: color(dark);
$footer-contacts-link-hover-color: $footer-contacts-link-color;
$footer-contacts-link-store-icon-height: rem-calc(35);
$footer-contacts-link-store-icon-width: rem-calc(35);
$footer-contacts-link-phone-icon-height: rem-calc(35);
$footer-contacts-link-phone-icon-width: rem-calc(35);
$footer-contacts-link-background: color(light);
$footer-contacts-border-bottom: none;
$footer-contacts-link-justify-content: space-between;
$footer-contacts-link-flex-basis--medium-down: 100%;
$footer-contacts-link-last-child-margin: 0;
$footer-contacts-link-text-transform: lowercase;
$footer-contacts-link-font: 300 #{rem-calc(14)} / 1.3 $font-primary;
$footer-contacts-link-radius: 0;

@import "@lora/05-components/footer/footer-contacts";