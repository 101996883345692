$tabs-tab-text-transform: lowercase;
$tabs-tab-text-transform--large: $tabs-tab-text-transform;
$tabs-tab-hover-color: color(primary-active);
$tabs-tab-padding: rem-calc(0 20 10);
$tabs-tab-padding--large: rem-calc(0 35 10);

//Default tabs
$tabs-tab-font: 300 #{rem-calc(14)} / 1.45 $font-primary;
$tabs-tab-font--large: normal #{rem-calc(26)} / #{rem-calc(30)} $font-secondary;
$tabs-tab-active-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$tabs-tab-active-color: color(primary-active);
$tabs-tab-active-after-border-bottom: 2px solid color(primary-active);

// Tabs secondary
$tabs-secondary-controls-background: transparent;
$tabs-secondary-control-items-border-bottom: none;
$tabs-secondary-control-items-justify-content: center;
$tabs-secondary-content-background: transparent;
$tabs-secondary-tab-font-family: $font-primary;
$tabs-secondary-tab-font-size: rem-calc(14);
$tabs-secondary-tab-font-size--large: $tabs-secondary-tab-font-size;
$tabs-secondary-tab-font-weight: 300;
$tabs-secondary-tab-font-weight--large: $tabs-secondary-tab-font-weight;
$tabs-secondary-tab-line-height: 1.45;
$tabs-secondary-tab-line-height--large: $tabs-secondary-tab-line-height;
$tabs-secondary-tab-active-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$tabs-secondary-tab-padding: rem-calc(0 20 5);
$tabs-secondary-tab-padding--large: rem-calc(0 35 15);

// Tabs tertiary
$tabs-tertiary-tab-color: color(text-secondary);
$tabs-tertiary-tab-active-font-weight: normal;
$tabs-tertiary-tab-active-font-size: rem-calc(14);
$tabs-tertiary-tab-font: 300 #{rem-calc(14)} / 1.45 $font-primary;
$tabs-tertiary-tab-font--large: $tabs-tertiary-tab-font;
$tabs-tertiary-controls-background-color: color(light);
$tabs-tertiary-tab-active-font-weight: normal;

@import "@lora/05-components/tabs";