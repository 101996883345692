$minicart-background: color(light);
$minicart-scrollable-max-height: rem-calc(505);
$minicart-promotions-border: none;

$minicart-actions-background: color(light);
$minicart-actions-padding: rem-calc(17 105 35 100);

$minicart-empty-width: rem-calc(670);
$minicart-empty-content-background: color(global-background);
$minicart-empty-content-width: 55%;
$minicart-empty-recommendation-width: 45%;
$minicart-empty-recommendation-padding: rem-calc(35 17 35 15);
$minicart-empty-text-transform: lowercase;
$minicart-empty-text-font: #{rem-calc(26)} / 1.15 $font-secondary;
$minicart-empty-checkout-link-font: normal #{rem-calc(14)} / 1.4 $font-primary;
$minicart-empty-checkout-link-text-transform: lowercase;
$minicart-empty-text-margin: rem-calc(0 80 20);
$minicart-empty-background: color(global-background);
$minicart-empty-title-text-transform: lowercase;

$minicart-product-title-text-transform: lowercase;
$minicart-product-title-font: normal #{rem-calc(14)} / 1.4 $font-primary;
$minicart-product-title-margin: rem-calc(0 0 60);

$minicart-remove-icon-display: false;
$minicart-remove-margin: rem-calc(35 0 0);
$minicart-remove-font: normal #{rem-calc(12)} / 1.5 $font-primary;

$minicart-subtotal-title-text-transform: lowercase;
$minicart-subtotal-title-font: normal #{rem-calc(14)} / #{rem-calc(20)} $font-tertiary;
$minicart-subtotal-price-font: $minicart-subtotal-title-font;
$minicart-subtotal-title-padding: rem-calc(15 30 15 100);
$minicart-subtotal-price-padding: rem-calc(15 105 15 15);
$minicart-subtotal-background: color(light);

$minicart-td-thumbnail-padding: rem-calc(35 15 0 100);
$minicart-td-thumbnail-width: rem-calc(285);
$minicart-td-padding: rem-calc(35 15 0);
$minicart-td-qty-font: normal #{rem-calc(14)} / 1.4 $font-primary;
$minicart-td-price-padding: rem-calc(35 100 0 15);
$minicart-td-price-text-align: right;

$minicart-th-text-transform: lowercase;
$minicart-th-details-padding: rem-calc(70 15 0 100);
$minicart-th-qty-padding: rem-calc(70 35 0 20);
$minicart-th-price-padding: rem-calc(70 100 0 35);
$minicart-th-details-font: #{rem-calc(18)} / 1.3 $font-secondary;
$minicart-th-qty-font: normal #{rem-calc(14)} / 1.4 $font-primary;
$minicart-th-price-font: $minicart-th-qty-font;

$minicart-header-border: rem-calc(1) solid color(border);
$minicart-recommendation-padding: rem-calc(10 50 30);

// Footer
$minicart-footer-border: none;
$minicart-footer-content-background: color(global-background);

@import "@lora/05-components/minicart";