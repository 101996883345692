// Header
$header-is-full-width: true;
$header-main-area-padding: rem-calc(6 0 6 25);
$header-background: color(dark);
$header-main-area-padding--large: 0;
$header-sticked-background: color(organism-background);
$header-sticked-search-button-icon-color: color(light);
$header-main-area-padding--large: rem-calc(0 20 0 20);

// Header Bottom
$header-bottom-background--large: color(organism-background);

// Logo
$header-logo-max-width: rem-calc(98);
$header-logo-max-width--large: rem-calc(207);
$header-logo-padding--large: rem-calc(27 0);
$header-sticked-logo-max-width--large: rem-calc(134);

// Hamburger
$header-hamburger-width: rem-calc(46);

// Search button
$header-search-button-icon-color: color(light);
$header-search-button-background--large: color(primary-active);
$header-search-button-background-hover--large: color(light);
$header-search-button-color-hover--large: color(dark);
$header-search-button-icon-color-hover--large: $header-search-button-color-hover--large;
$header-search-button-active-icon-color: color(primary);
$header-search-button-padding--large: rem-calc(16);
$header-search-button-border--large: 1px solid color(organism-background);
$header-search-button-label-margin: rem-calc(0 0 0 8);
$header-search-button-text-transform: capitalize;
$header-sticked-search-button-border--large: none;
$header-sticked-search-button-hover-icon-color: color(primary-active);

// Context components
$header-top-nav-storeslink-padding: 0;

// Top nav item
$header-top-nav-item-color: color(light);
$header-top-nav-item-hover-color: color(primary-active);
$header-top-nav-item-separator-color: transparent;
$header-top-nav-item-font-size--large: rem-calc(10);
$header-top-nav-item-text-transform: lowercase;
$header-top-nav-item-text-transform--large: lowercase;
$header-top-nav-item-account-display--large: block;
$header-sticked-top-nav-item-margin--large: rem-calc(0 0 0 10);
$header-top-nav-item-font-weight--large: 300;
$header-top-nav-link-font: 300 #{rem-calc(10)} / 1 $font-primary-light;

// Minicart
$header-sticked-minicart-link-margin--large: rem-calc(0 -5);
$header-sticked-minicart-icon-color: color(light);

// Top nav link
$header-top-nav-link-text-transform: lowercase;
$header-navigation-link-level-1-hover-color--large: color(secondary);

// User button
$header-user-link-account-sticky-login-icon-color: color(light);
$header-user-link-account-sticky-login-icon-height: rem-calc(30);
$header-user-link-account-sticky-login-icon-width: rem-calc(30);
$header-user-link-account-sticky-login-icon-hover-color: color(primary-active);

$header-search-button-icon-width: rem-calc(16);
$header-search-button-icon-height: rem-calc(16);
$header-hamburger-border--large: none;
$header-search-button-color--large: color(secondary);
$header-search-button-icon-color--large: color(secondary);
$header-search-button-font: 400 #{rem-calc(14)} / 1.2 $font-primary;
$header-hamburger-border-pseudo-offset--large: 0;

@import "@lora/04-layout/header";
