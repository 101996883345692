$header-user-menu-font: 400 #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;
$header-user-menu-justify-content: flex-start;
$header-user-menu-padding: rem-calc(16);
$header-user-menu-sign-in-text-decoration: underline;
$header-user-menu-border: solid color(element-background);
$header-user-menu-border-width: 1px 1px 1px 0;
$header-user-menu-title-margin: rem-calc(0 10 0 0);
$header-user-menu-content-title-height: rem-calc(50);
$header-user-menu-background: color(global-background);

// Content
$header-user-menu-title-flex-direction: row;
$header-user-menu-text-transform: lowercase;

// Content title
$header-user-menu-content-title-text-transform--medium-down: lowercase;
$header-user-menu-content-title-border--medium-down: solid color(light);
$header-user-menu-content-title-border-width--medium-down: 1px;
$header-user-menu-title-font: 400 #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;

// Back button
$header-user-menu-back-button-height: rem-calc(49);
$header-user-menu-back-button-text-transform: lowercase;
$header-user-menu-back-button-font: #{rem-calc(12)} / 1.4 $font-primary;
$header-user-menu-content-link-text-transform: lowercase;
$header-user-menu-content-title-font-size: rem-calc(14);
$header-user-menu-content-title-font-weight--medium-down: normal;

// Content link
$header-user-menu-content-link-font-weight: normal;
$header-user-menu-content-link-font-size: rem-calc(12);
$header-user-menu-content-link-logout-text-transform: lowercase;
$header-user-menu-content-link-logout-margin: rem-calc(20 18 0);
$header-user-menu-content-link-logout-margin--large: rem-calc(10 0 0);
$header-user-menu-content-link-logout-background: color(primary);
$header-user-menu-content-link-logout-hover-background: color(primary-active);

// Menu secondary list
$header-user-menu-secondary-list-margin: rem-calc(40 60);
$header-user-menu-secondary-list-border-top: 1px solid color(border);

// Menu secondary link
$header-user-menu-secondary-link-height: rem-calc(50);
$header-user-menu-secondary-link-padding: rem-calc(8 20);
$header-user-menu-secondary-link-font-weight: 300;

// Menu secondary logout cta
$header-user-menu-secondary-logout-icon-size: rem-calc(20);
$header-user-menu-secondary-logout-icon-width: rem-calc(150);

$header-user-menu-logout-link-color: color(primary);
$header-user-menu-logout-link-font: #{rem-calc(14)} / rem-calc(16.8) $font-primary;
$header-user-menu-icon-size: rem-calc(16);

@import "@lora/05-components/header/header-user-menu";
