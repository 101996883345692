$footer-nav-list-level1-margin--large: rem-calc(22 0 24);
$footer-nav-link-level1-font--large: normal #{rem-calc(12)} / rem-calc(16) $font-primary;
$footer-nav-link-level2-font--large: 300 #{rem-calc(12)} / 1.5 $font-primary;
$footer-nav-link-level2-hover-color: color(primary-active);
$footer-nav-link-level1-text-transform: lowercase;
$footer-nav-link-level1-text-transform--large: $footer-nav-link-level1-text-transform;
$footer-nav-link-level2-text-transform: lowercase;
$footer-nav-link-level2-padding--large: rem-calc(0 0 11);
$footer-nav-list-level2-margin: rem-calc(0);

@import "@lora/05-components/footer/footer-nav";