$tag-link-background: color(secondary);
$tag-link-border: 1px solid color(primary-active);
$tag-link-border-width: 0 0 0 4px;
$tag-link-box-shadow: rem-calc(1 4 5 2) rgba(0, 0, 0, 0.1);
$tag-link-color: color(dark);
$tag-font: normal #{rem-calc(12)} / 1.4 $font-primary;
$tag-text-transform: lowercase;
$tag-padding--large: rem-calc(7 20);
$tag-margin--large: rem-calc(0 10 20 0);
$tag-link-active-background: color(light);
$tag-link-active-color: color(primary);

@import "@lora/05-components/tag";