$content-grid-title-text-transform: lowercase;
$content-grid-title-font: normal #{rem-calc(26)} / #{rem-calc(30)} $font-secondary;
$content-grid-title-font--large: normal #{rem-calc(34)} / #{rem-calc(38)} $font-secondary;
$content-grid-title-text-align: center;
$content-grid-description-text-transform: lowercase;
$content-grid-description-color: color(text-secondary);
$content-grid-description-margin: rem-calc(0 0 30);
$content-grid-description-text-align: center;
$content-grid-article-border-bottom: 1px solid color(border);
$content-grid-article-border-bottom--large: none;

@import "@lora/05-components/content-grid";