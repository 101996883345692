$form-text-field-sizes: (
    "small": $global-input-primary-height,
    "normal": $global-input-primary-height,
    "large": $global-input-primary-height
);
$form-text-field-radius: 0;
$form-text-field-box-shadow: rem-calc(0 1 0 0) color(primary);
$form-text-field-box-shadow-hover: rem-calc(0 2 0 0) color(primary);
$form-text-field-box-shadow-focus: rem-calc(0 1 0 0) color(primary-active);
$form-text-field-border-hover: 0;
$form-text-field-border-focus: 0;
$form-text-field-padding: rem-calc(0 10);
$form-text-field-border: none;
$form-text-field-counter-font-size: rem-calc(10);
$form-text-field-textarea-min-height: rem-calc(50);
$form-text-field-textarea-padding: rem-calc(10);
$form-text-field-font-weight: normal;
$form-text-field-font-family: $font-tertiary;
$form-text-field-password-button-icon-width: rem-calc(23);
$form-text-field-password-button-icon-height: rem-calc(55);

@import "@lora/05-components/form/text-field";