$product-set-separator-before-border-top: none;
$product-set-separator-title-display: none;
$product-set-separator-margin: rem-calc(40 0 0);
$product-set-margin--large: rem-calc(35 0 0);
$product-set-padding: rem-calc(40 0 0);
$product-set-padding--large: rem-calc(20 0 0);

// Product set subtitle
$product-set-subtitle-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$product-set-subtitle-text-transform: lowercase;

// Product set list and item
$product-set-item-margin: rem-calc(0 0 35);

@import "@lora/05-components/product/product-set";