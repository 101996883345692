$section-headings-max-width--large: 100%;

// Title
$section-title-font: #{rem-calc(26)} / #{rem-calc(30)} $font-secondary;
$section-title-font--large: #{rem-calc(34)} / #{rem-calc(38)} $font-secondary;
$section-title-text-transform: lowercase;
$section-title-margin: rem-calc(0 0 25);
$section-title-margin--large: rem-calc(0 0 25);
$section-title-text-align: center;
$section-secondary-title-font: #{rem-calc(26)} / #{rem-calc(30)} $font-secondary;
$section-secondary-title-font--large: #{rem-calc(18)} / 1.3 $font-secondary;
$section-title-secondary-text-transform--large: lowercase;
$section-secondary-title-text-align: center;
$section-secondary-title-text-align--large: left;

// description
$section-description-font: 300 #{rem-calc(12)} / 1.4 $font-primary;
$section-description-font--large: 300 #{rem-calc(14)} / 1.45 $font-primary;
$section-description-color: color(text);
$section-description-color--large: color(text-secondary);
$section-description-text-transform: lowercase;

// View more
$section-view-more-link-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$section-view-more-link-font--large: $section-view-more-link-font;
$section-view-more-link-text-transform: lowercase;
$section-view-more-link-text-transform--large: $section-view-more-link-text-transform;

$section-content-margin--large: rem-calc(0);

@import "@lora/05-components/section";