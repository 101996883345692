$header-wishlist-status-color: color(dark);
$header-wishlist-status-counter-color: color(dark);
$header-wishlist-status-counter-border: rem-calc(1) solid color(dark);
$header-wishlist-status-width: rem-calc(11);
$header-wishlist-status-height: rem-calc(10);
$header-wishlist-status-counter-width: rem-calc(16);
$header-wishlist-status-counter-height: rem-calc(16);
$header-wishlist-status-counter-right: rem-calc(-10);
$header-wishlist-status-counter-top: rem-calc(-11);

@import "@lora/05-components/header/header-wishlist-status";