// Items
$header-navigation-item-level-2-view-all-border: none;
$header-navigation-item-level-3-border: none;
$header-navigation-item-level-3-first-border: none;
$header-navigation-list-level-3-margin--large: rem-calc(0 0 0);
$header-navigation-list-level-2-padding--large: rem-calc(25 0);
$header-navigation-item-level-3-all-link-font: 300 #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;
$header-navigation-item-level-3-all-link-font--large: normal #{rem-calc(14)} / 1.45 $font-primary;
$header-navigation-list-level-1-justify-content--large: space-between;
$header-navigation-item-level-3-padding--large: rem-calc(12 0 0);

// Item title
$header-navigation-item-title-level-1-font: #{rem-calc(14)} / 1.2 $font-primary;
$header-navigation-item-title-accordion-padding: rem-calc(15.5 50 15.5 0);
$header-navigation-item-title-padding: rem-calc(15.5 0);
$header-navigation-item-title-level-1-font-size: rem-calc(12);
$header-navigation-item-title-level-1-font-size--large: rem-calc(14);
$header-navigation-item-title-level-1-padding-left: rem-calc(16);
$header-navigation-item-title-level-1-color--large: color(light);
$header-navigation-item-title-level-2-padding-left: rem-calc(24);
$header-navigation-item-title-level-1-expanded-background: color(light);
$header-navigation-item-title-level-1-expanded-color: color(dark);
$header-navigation-item-title-level-2-expanded-background: color(light);
$header-navigation-item-title-level-2-expanded-color: color(dark);
$header-navigation-item-title-level-2-background: color(global-background);
$header-navigation-item-title-level-3-background: color(light);
$header-navigation-item-title-accordion-text-transform: lowercase;
$header-navigation-item-title-level-2-font-weight: 300;
$header-navigation-item-title-level-2-font-weight--large: normal;
$header-navigation-item-title-level-2-font-size--large: rem-calc(14);
$header-navigation-container-content-padding: rem-calc(8 16 16);
$header-navigation-container-grid-list-level-2-padding--large: rem-calc(0 0 16 0);
$header-navigation-container-grid-padding--xlarge: rem-calc(24 0 0);
$header-navigation-item-title-level-1-expanded-font: 700 #{rem-calc(14)} / 1.2 $font-primary;

// Links
$header-navigation-link-text-transform: lowercase;
$header-navigation-link-level-3-padding--medium-down: rem-calc(19 0 19 60);
$header-navigation-link-level-1-padding--large: rem-calc(17 16 18);
$header-navigation-link-level-1-font-family: $font-tertiary;
$header-navigation-link-level-1-font-weight--large: normal;
$header-navigation-link-level-1-font-size: rem-calc(14);
$header-navigation-link-level-1-font-size--large: rem-calc(14);
$header-navigation-link-hover-font-weight: normal;
$header-navigation-link-level-3-color--large: color(text-secondary);
$header-navigation-link-level-3-font-weight: 300;
$header-navigation-link-level-3-hover-font-weight: 300;
$header-navigation-link-level-1-font-family--large: $font-tertiary;
$header-navigation-link-level-1-font-size--large: rem-calc(14);
$header-navigation-item-level-2-all-link-text-decoration: underline;
$header-navigation-item-level-2-all-link-text-decoration--large: underline;
$header-navigation-item-level-2-all-link-font: 300 #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;
$header-navigation-item-level-2-all-link-font--large: 300 #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;

// Content tile
$header-navigation-content-title-text-transform--large: lowercase;
$header-navigation-content-title-font--large: normal #{rem-calc(14)} / 1.45 $font-primary;

// Flyout
$header-navigation-flyout-element-background--large: color(light);
$header-navigation-link-level-1-hover-text-decoration--large: none;
$header-navigation-link-level-1-hover-font-weight: 700;
$header-navigation-link-level-2-text-transform: capitalize;
$header-navigation-item-level-2-padding--large: rem-calc(0 12 0);
$header-navigation-item-title-level-1-font: #{rem-calc(14)} / 1.2 $font-primary;
$header-navigation-item-title-level-2-font: 400 #{rem-calc(14)} / 1.2 $font-primary;
$header-navigation-item-title-level-3-font: #{rem-calc(14)} / 1.2 $font-primary;
// Accordion icon
$header-navigation-item-title-accordion-icon-size: rem-calc(12);
$header-navigation-item-title-accordion-icon: 'hamburger-chevron2-down';

@import "@lora/05-components/header/header-navigation";