$modal-wrapper-width--large: rem-calc(970);
$modal-wrapper-padding--large: rem-calc(65 100 70 100);
$modal-wrapper-padding: rem-calc(78 25 30);
$modal-close-position: rem-calc(20);
$modal-close-position--medium: rem-calc(25);
$modal-body-text-transform: lowercase;
$modal-body-font: 300 #{rem-calc(14)} / 1.45 $font-primary;

// Title
$modal-title-margin: rem-calc(0 0 28);
$modal-title-margin--large: rem-calc(0 0 28);
$modal-title-font: #{rem-calc(18)} / #{rem-calc(24)} $font-secondary;
$modal-title-font--large: #{rem-calc(18)} / #{rem-calc(24)} $font-secondary;
$modal-title-text-transform: lowercase;
$modal-header-secondary-title-margin: rem-calc(0 -25 28 -25);

// Subtitle
$modal-subtitle-text-transform: lowercase;
$modal-subtitle-font: normal #{rem-calc(14)} / 1.25 $font-primary;
$modal-subtitle-font--large: $modal-subtitle-font;
$modal-subtitle-margin--large: rem-calc(49 0 8);

// Text
$modal-text-font-size: rem-calc(14);
$modal-text-large-font-size: rem-calc(14);
$modal-text-line-height: 1.5;
$modal-text-font-weight: 300;
$modal-text-large-font-weight: 300;
$modal-text-color: color(text-secondary);
$modal-text-text-transform: lowercase;

// Modal with image
$modal-image-padding: rem-calc(50 25 25);
$modal-image-content-padding--large: rem-calc(80 70 60);

$modal-section-secondary-background: color(light);
$modal-section-secondary-background--large: color(light);
$modal-section-secondary-padding: 0;
$modal-section-secondary-padding--large: rem-calc(15 25);

/// Action sticky
$modal-actions-sticky-box-shadow: rem-calc(0 -1 8 0) rgba(color(dark), 0.16);

// Close icon
$modal-close-icon-size: rem-calc(12);
$modal-close-icon-size--large: rem-calc(20);
$modal-close-position: rem-calc(26);

// Modal slide
/// Title
$modal-header-secondary-title-background: color(light);
$modal-header-secondary-title-color: color(dark);
$modal-header-secondary-title-font: normal #{rem-calc(14)} / #{rem-calc(20)} $font-tertiary;

// Modal text align
$modal-title-text-align--large: left;

@import "@lora/05-components/modal";