$header-suggestions-title-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$header-suggestions-title-font--large: $header-suggestions-title-font;
$header-suggestions-title-padding: rem-calc(32 0 0);
$header-suggestions-title-text-transform: lowercase;
$header-suggestions-view-all-text-transform: lowercase;
$header-suggestions-view-all-text-transform--large: $header-suggestions-view-all-text-transform;
$header-suggestions-view-all-font: normal #{rem-calc(12)} / 1.2 $font-primary;
$header-suggestions-view-all-font--large: $header-suggestions-view-all-font;
$header-suggestions-view-all-color: color(text);
$header-suggestions-products-name-text-transform: lowercase;
$header-suggestions-products-name-text-transform--large: lowercase;
$header-suggestions-products-description-text-transform: lowercase;
$header-suggestions-products-image-margin: rem-calc(0 20 0 0);
$header-suggestions-products-list-separator: 1px solid color(border);
$header-suggestions-products-list-separator--large: none;
$header-suggestions-products-separator: none;
$header-suggestions-aside-title-text-transform: lowercase;
$header-suggestions-aside-title-color: color(text);
$header-suggestions-aside-title-font: normal #{rem-calc(12)} / 1.5 $font-primary;
$header-suggestions-aside-link-text-transform: lowercase;
$header-suggestions-aside-link-font: 300 #{rem-calc(12)} / 1.5 $font-primary;
$header-suggestions-aside-link-padding: rem-calc(5 0);
$header-suggestions-aside-link-padding--large: $header-suggestions-aside-link-padding;
$header-suggestions-aside-link-hover-color: color(primary-active);
$header-suggestions-products-price-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$header-suggestions-products-name-color: color(text);
$header-suggestions-products-name-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$header-suggestions-products-description-font: 300 #{rem-calc(12)} / 1.5 $font-primary;
$header-suggestions-text-match-font: normal #{rem-calc(14)} / 1.25 $font-primary;
$header-suggestions-text-match-font--large: $header-suggestions-text-match-font;
$header-suggestions-text-link-text-transform: lowercase;
$header-suggestions-view-all-top: rem-calc(32);
$header-suggestions-text-font: #{rem-calc(14)} / 1.25 $font-primary-light;
$header-suggestions-view-all-font: 500 #{rem-calc(14)} / 1.25 $font-tertiary;

@import "@lora/05-components/header/header-suggestions";