$product-tile-overlay-button-min-width: rem-calc(140);

@import "@root/05-components/product/product-tile";

$product-tile-name-line-height: 1.6;
$product-tile-badge-max-width: max-content;

.c-product-tile__swatch-group {
    .c-swatches {
        margin: rem-calc(8 0 0 0);
    }
}
