$form-check-field-label-text-transform: lowercase;
$form-check-field-label-font-weight: 300;
$form-check-field-label-side-margin: rem-calc(8);
$form-check-field-radio-checked-control-font-weight: 300;

// Checkboxes
$form-check-field-disabled-control-background: color(light);
$form-check-field-checkbox-control-focus-box-shadow: none;
$form-check-field-checkbox-control-active-box-shadow: none;
$form-check-field-checkbox-checked-icon: tick;
$form-check-field-checkbox-checked-icon-color: color(dark);
$form-check-field-checkbox-checked-icon-width: rem-calc(20);
$form-check-field-checkbox-checked-icon-height: rem-calc(20);
$form-check-field-checkbox-checked-icon-position--left: 0;
$form-check-field-checkbox-checked-icon-position--top: 0;
$form-check-field-checkbox-checked-control-background: color(light);
$form-check-field-checkbox-checked-control-font-weight: 400;
$form-check-field-checkbox-control-background: transparent;
$form-check-field-checkbox-control-border: 1px solid color(element-background);

//label image
$form-check-field-image-checked-label-border: 1px solid color(dark);

/// Unchecked Radio Hover, Focus, Active states
$form-check-field-radio-control-hover-box-shadow: none;
$form-check-field-radio-control-focus-box-shadow: none;
$form-check-field-radio-control-active-box-shadow: none;

/// Checked Radio Hover, Focus, Active states
$form-check-field-radio-checked-control-hover-box-shadow: none;
$form-check-field-radio-checked-control-focus-box-shadow: none;
$form-check-field-radio-checked-control-active-box-shadow: none;

/// Unchecked Hover, Focus, Active states
$form-check-field-checkbox-control-hover-box-shadow: none;
$form-check-field-checkbox-control-focus-box-shadow: none;
$form-check-field-checkbox-control-active-box-shadow: none;

/// Checked Hover, Focus, Active states
$form-check-field-checkbox-checked-control-hover-box-shadow: none;
$form-check-field-checkbox-checked-control-focus-box-shadow: none;
$form-check-field-checkbox-checked-control-active-box-shadow: none;
$form-check-field-radio-control-background: color(dark);
$form-check-field-radio-control-size: rem-calc(43);
$form-check-field-radio-control-border: #{$form-check-field-radio-control-size / 2} solid color(light);
$form-check-field-radio-checked-control-border-width: #{$form-check-field-radio-control-size / 6};

/// Unchecked Hover, Focus, Active states
$form-check-field-switch-after-hover-box-shadow: none;
$form-check-field-switch-after-focus-box-shadow: none;
$form-check-field-switch-after-active-box-shadow: none;
$form-check-field-label-font-size: rem-calc(12);

/// Before and After
$form-check-field-switch-before-background: color(element-background);
$form-check-field-switch-before-border-radius: rem-calc(15);
$form-check-field-switch-before-width: rem-calc(52);
$form-check-field-switch-before-height: rem-calc(30);
$form-check-field-switch-before-position--top: rem-calc(-4);
$form-check-field-switch-after-position--top: 0;
$form-check-field-switch-after-background: color(organism-background);
$form-check-field-switch-after-position--right: rem-calc(25);
$form-check-field-switch-checked-after-background: color(secondary);
$form-check-field-switch-checked-after-position--right: rem-calc(5);
$form-check-field-switch-padding: rem-calc(0 65 0 0);

@import "@lora/05-components/form/check-field";