$footer-social-list-flex-wrap: wrap;
$footer-social-title-font: normal #{rem-calc(14)} / 1.25 $font-primary;
$footer-social-title-font--large: normal #{rem-calc(14)} / 1.25 $font-primary;
$footer-social-link-border-radius: 50%;
$footer-social-link-border: 1.5px solid color(light);
$footer-social-link-hover-border: 1.5px solid color(primary-active);
$footer-social-list-padding: 0;
$footer-social-list-padding--large: rem-calc(18 27 0 0);
$footer-social-link-hover-color: color(primary-active);
$footer-social-title-text-transform: lowercase;
$footer-social-link-padding: rem-calc(4.5);

$footer-social-list: (
    facebook: (
        enabled: true,
        icon: facebook-social,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    twitter: (
        enabled: true,
        icon: twitter-social,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    google: (
        enabled: true,
        icon: google-social,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    pinterest: (
        enabled: true,
        icon: pinterest-social,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    instagram: (
        enabled: true,
        icon: instagram,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    youtube: (
        enabled: true,
        icon: youtube,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
);

@import "@lora/05-components/footer/footer-social";