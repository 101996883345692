$progress-bar-height: rem-calc(7);
$progress-bar-background-color: color(global-background);
$progress-bar-value-background-color: color(primary);
$progress-bar-border: rem-calc(1) solid color(primary);
$progress-bar-padding: rem-calc(0);
$progress-bar-border-radius: rem-calc(7.5);
$progress-bar-margin: rem-calc(10 4);
$progress-bar-after-background-color: transparent;
$progress-bar-before-icon-size: rem-calc(7);
$progress-bar-before-active-icon-size: rem-calc(7);
$progress-bar-point-price-color: color(primary);
$progress-bar-point-description-color: color(secondary);
$progress-bar-before-active-icon: check;
$progress-bar-before-icon: cross-1;
$progress-bar-background-color: color(light);

@import "@lora/05-components/progress-bar";