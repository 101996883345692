$form-label-float-padding: rem-calc(0);
$form-label-float-font-size: rem-calc(14);
$form-label-float-font-weight: 300;
$form-label-float-focus-font-size: rem-calc(10);
$form-label-float-focus-color: color(dark);
$form-label-float-focus-padding: rem-calc(0);
$form-label-float-left: 0;
$form-label-float-text-transform: lowercase;
$form-label-float-before-height: calc(50% + 4px);

@import "@lora/05-components/form/label";