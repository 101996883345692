$button-font-weight: 300;
$button-font-family: $font-primary-light;

/// Default border color for buttons.
/// @type Color
$button-border: 1px solid color(border);

/// Primary button color.
/// @type Map
$button-primary: (
    background: color(primary),
    color: color(light),
    color-hover: color(light),
    background-hover: color(primary-active),
);

/// Secondary button color.
/// @type Map
$button-secondary: (
    background: color(light),
    background-hover: color(primary-active),
    color: color(primary),
    color-hover: color(secondary),
    border: 1px solid color(element-background),
    border-hover: 1px solid color(primary-active)
);

/// @type Map
$button-sizes: (
    tiny: (
        padding: rem-calc(17 20),
        font-size: rem-calc(8),
    ),
    small: (
        padding: rem-calc(17 20),
        font-size: rem-calc(10),
    ),
    large: (
        padding: rem-calc(17 20),
        font-size: rem-calc(14),
    ),
);

/// Default font size for buttons.
/// @type Number
$button-font-size: rem-calc(14);

// Social buttons
$button-social-list: (
    facebook: (
        icon: facebook,
        icon-color: color(light),
        icon-color-hover: color(light),
        background: null,
        background-hover: null,
        color: null,
        border: null
    ),
    google: (
        icon: google,
        icon-color: color(light),
        icon-color-hover: color(light),
        background: null,
        background-hover: null,
        color: null,
        border: null
    )
);

$button-text-transform: lowercase;
$button-padding: rem-calc(17 20);
$button-social-icon-left: false;
$button-social-icon-text-transform: lowercase;
$button-social-icon-size: rem-calc(25);
$button-alt-background: color(primary);
$button-alt-hover-background: color(primary-active);
$button-alt-border: 1px solid color(element-background);
$button-disabled-color: color(light);
$button-link-text-transform: lowercase;
$button-alt-color: color(light);
$button-alt-hover-color: color(light);
$button-alt-hover-border: 1px solid color(primary-active);
$button-print-font-weight: normal;
$button-link-font-weight: normal;
$button-min-width: rem-calc(202);
$button-search-height: rem-calc(50);
$button-icon-only-height: rem-calc(50);

@import "@lora/05-components/button";