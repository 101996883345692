$product-tile-thumbnail-margin: 0;
$product-tile-default-text-align: center;
$product-tile-rating-align: center;

$product-tile-description-color: color(text-secondary);
$product-tile-description-font-weight: 300;
$product-tile-description-text-transform: lowercase;
$product-tile-description-margin: rem-calc(8) 0;
$product-tile-description-margin--large: $product-tile-description-margin;
$product-tile-description-line-height: 1.3;

$product-tile-grid-name-text-align: left;
$product-tile-grid-description-text-align: left;
$product-tile-grid-info-item-align-self: flex-start;
$product-tile-grid-list-align-items: flex-start;
$product-tile-grid-figure-padding: rem-calc(20 10 0 0);
$product-tile-grid-caption-padding: 0;
$product-tile-grid-caption-margin: rem-calc(25 0 0);

$product-tile-info-item-margin: rem-calc(8 0);
$product-tile-info-item-margin--large: rem-calc(10 0 0);

$product-tile-name-font-weight: normal;
$product-tile-name-font-family: $font-primary;
$product-tile-name-line-height: 1.4;
$product-tile-name-margin: rem-calc(8) 0;
$product-tile-name-text-transform: lowercase;
$product-tile-name-color-visited: color(primary);
$product-tile-name-color-hover: color(primary-active);
// Product Price
$product-tile-price-font: 400 #{rem-calc(14)} / 1.3 $font-primary;
$product-tile-price-font--large: 400 #{rem-calc(14)} / 1.4 $font-primary;
$product-tile-price-padding: rem-calc(5) 0 0 ;

$product-tile-swatch-selected-font-size: rem-calc(10);
$product-tile-swatch-selected-font-size--large: rem-calc(12);
$product-tile-swatch-selected-text-transform: lowercase;
$product-tile-swatch-name-margin: rem-calc(0 0 15);

$product-tile-variation-group-margin: rem-calc(10) auto 0;
$product-tile-variation-group-margin--large: rem-calc(10) auto 0;
$product-tile-variations-width--large: 100%;
$product-tile-variation-group-height: rem-calc(57);
$product-tile-variations-padding: rem-calc(10) 0 0;
// Product actions
$product-tile-actions-margin: rem-calc(8) 0 0;

$product-tile-variations-single-text-transform: lowercase;
$product-tile-rating-margin: 0 0 rem-calc(10);
// Product badge
$product-tile-badge-top: rem-calc(8);
// Product swatch
$product-tile-swatch-selected-color: color(text-secondary);
$product-tile-swatch-selected-font-weight: 300;
$product-tile-swatch-name-gap: 0 rem-calc(5);
$product-tile-swatch-name-margin: 0 0 rem-calc(4);
// Product add to bag
$product-tile-add-bag-text-transform: lowercase;
$product-tile-add-bag-text-font: 300 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
// Tile Secondary
$product-tile-secondary-border: none;

@import "@lora/05-components/product/product-tile";