$accordion-title-padding: rem-calc(17 0);
$accordion-title-secondary-text-transform: lowercase;
$accordion-title-secondary-color-expanded: color(text);
$accordion-title-secondary-background-color-expanded: color(light);
$accordion-title-secondary-font-weight-expanded: normal;
$accordion-title-font: normal #{rem-calc(12)} / #{rem-calc(16)} $font-tertiary;
$accordion-title-secondary-padding: rem-calc(17 50 17 15);
$accordion-title-secondary-padding--large: rem-calc(17 50 17 20);
$accordion-title-text-transform: lowercase;
$accordion-content-inner-padding-collapsed--large: rem-calc(20 20 20 50);
$accordion-content-font-size: rem-calc(14);
$accordion-icon-margin: rem-calc(0 14);
$accordion-icon-width: rem-calc(14);
$accordion-icon-color: color(primary);
$accordion-title-secondary-padding--large: rem-calc(15 49 15 50);

@import "@lora/05-components/accordion";