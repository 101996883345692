$back-top-background: color(primary);
$back-top-background-hover: color(primary-active);
$back-top-hover-border: color(primary-active);
$back-top-width: rem-calc(40);
$back-top-width--large: $back-top-width;
$back-top-height: rem-calc(40);
$back-top-height--large: $back-top-height;
$back-top-padding: 0;
$back-top-padding--large: $back-top-padding;
$back-top-icon-height: rem-calc(35);
$back-top-icon-width: rem-calc(35);
$back-top-icon-height--large: $back-top-icon-height;
$back-top-icon-width--large: $back-top-icon-width;
$back-top-border-radius: 0;
$back-top-bottom: rem-calc(120);

@import "@lora/05-components/back-top";