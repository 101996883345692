// View All Swatches
$product-swatches-view-all-link-font: normal #{rem-calc(14)} / 1.45 $font-primary;

// Swatch Selected
$product-swatches-selected-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$product-swatches-selected-font--large: $product-swatches-selected-font;
$product-swatches-selected-text-transform: lowercase;
$product-swatches-selected-color: color(text-secondary);

// Swatches Toggle
$product-swatches-toggle-font: 300 #{rem-calc(12)} / 1.3 $font-primary;
$product-swatches-toggle-font--large: $product-swatches-toggle-font;
$product-swatches-toggle-text-transform: lowercase;

$product-swatches-selected-text-transform: lowercase;
$product-swatches-selected-font: #{rem-calc(14)} / 1.5 $font-primary;

@import "@lora/05-components/product/product-swatches";