$product-badge-title-font--large: 300 #{rem-calc(10)} / #{rem-calc(16)} $font-primary;
$product-badge-title-padding: rem-calc(5 8 3 8);
$product-badge-title-text-decoration: none;
$product-badge-title-text-transform: lowercase;
$product-badge-title-border-radius: 0;
// Product badge category title
$product-badge-category-title-font: 300 #{rem-calc(10)} / #{rem-calc(16)} $font-primary;
$product-badge-category-title-text-transform: lowercase;
$product-badge-category-title-box-shadow: rem-calc(0 0 2 0) rgba(color(primary), 0.2);
$product-badge-category-title-border-left: rem-calc(4) solid color(primary-active);
$product-badge-category-title-border-radius: 0;
$product-badge-title-background: rgba(color(primary), 0.5);
$product-badge-title-color: color(secondary);

// Product badge title in variations
$product-badge-title-variations-font: #{rem-calc(11)} / #{rem-calc(13)} $font-secondary;
$product-badge-title-variations-padding: rem-calc(2 4);
$product-badge-title-variations-border-radius: 0;

@import "@lora/05-components/product/product-badge";