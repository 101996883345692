@import "@root/05-components/footer/footer-social";

$footer-social-list-justify-content: center;
$footer-social-list-justify-content--large: normal;
$footer-social-link-padding: rem-calc(10);
$footer-social-link-padding--large: rem-calc(10);
$footer-social-list: (
    facebook: (
        enabled: true,
        icon: facebook-social,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    twitter: (
        enabled: true,
        icon: twitter-social,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    google: (
        enabled: true,
        icon: google-social,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    pinterest: (
        enabled: true,
        icon: pinterest-social,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    instagram: (
        enabled: true,
        icon: instagram-social,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    line: (
        enabled: true,
        icon: footer-lineme,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
);