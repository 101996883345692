@import "@root/05-components/product/product-main-v2";

.c-product-main {
    &__variations-dropdown.m-color {
        display: block;
        width: 100%;
    }

    &__name {
        font-weight: 800;
    }
}
