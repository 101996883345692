$alert-info: (
    background: color(light),
    border: 1px solid color(element-background),
    color: color(organism-background),
    icon: "info",
    icon-color: color(dark)
);

$alert-warning: (
    background: color(global-background),
    border: 1px solid color(global-background),
    color: color(dark),
    icon: "alert",
    icon-color: color(organism-background)
);

$alert-success: (
    background: color(global-background),
    border: 1px solid color(global-background),
    color: color(dark),
    icon: "success",
    icon-color: color(success)
);

$alert-error: (
    background: color(global-background),
    border: 1px solid color(global-background),
    color: color(dark),
    icon: "error",
    icon-color: color(alert)
);

$alert-background: color(light);
$alert-color: color(dark);
$alert-padding: rem-calc(10 20 15);
$alert-padding--large: rem-calc(20 30);
$alert-font--large: #{rem-calc(12)} / #{rem-calc(20)} $font-primary;
$alert-message-text-transform: lowercase;

@import "@lora/05-components/alert";