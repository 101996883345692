$header-minicart-icon-color: color(light);
$header-minicart-icon-color--large: $header-minicart-icon-color;
$header-minicart-icon-hover-color: color(primary-active);
$header-minicart-icon-height: rem-calc(18);
$header-minicart-icon-width: rem-calc(15);
$header-minicart-icon-height--large: rem-calc(14);
$header-minicart-icon-width--large: rem-calc(11);
$header-minicart-icon-qty-background: color(primary-active);
$header-minicart-icon-qty-font-size: rem-calc(6);
$header-minicart-icon-qty-right: 0;
$header-minicart-icon-qty-bottom: rem-calc(14);
$header-minicart-icon-qty-height: rem-calc(17);
$header-minicart-icon-qty-width: rem-calc(17);
$header-minicart-icon-svg-order--large: 3;
$header-minicart-icon-label-order--large: 1;
$header-minicart-icon-qty-order--large: 2;
$header-minicart-icon-link-align-items: flex-start;
$header-minicart-icon-svg-margin--large: rem-calc(-2 0 0 10);
$header-minicart-icon-label-margin--large: rem-calc(0 3 0 0);
$header-minicart-icon-qty-sticky-font-size--large: rem-calc(7);
$header-minicart-icon-link-padding--large: 0;
$header-minicart-icon-qty-sticky-right--large: rem-calc(-18);
$header-minicart-icon-link-hover-color: color(primary-active);

@import "@lora/05-components/header/header-minicart-icon";