$locale-selector-select-secondary-color: color(dark);
$locale-selector-title-font: normal #{rem-calc(26)} / 1.4 $font-secondary;
$locale-selector-title-text-transform: lowercase;
$locale-selector-description-font: 300 #{rem-calc(14)} / 1.4 $font-primary;
$locale-selector-description-text-transform: lowercase;
$locale-selector-stores-stores-link-text-transform: lowercase;
$locale-selector-item-link-text-transform: lowercase;
$locale-selector-item-link-font: normal #{rem-calc(12)} / 1.4 $font-primary;
$locale-selector-item-title-text-transform: lowercase;
$locale-selector-select-secondary-arrow-color: color(dark);
$locale-selector-select-secondary-hover-color: color(dark);
$locale-selector-select-secondary-hover-background-color: color(light);
$locale-selector-select-secondary-hover-border: 1px solid transparent;

@import "@lora/05-components/locale-selector";