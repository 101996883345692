$error-callout-title-font: normal #{rem-calc(26)} / #{rem-calc(30)} $font-secondary;
$error-callout-title-font--large: normal #{rem-calc(34)} / #{rem-calc(38)} $font-secondary;
$error-callout-subtitle-font: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary;
$error-callout-subtitle-font--large: 300 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$error-callout-title-color: color(secondary);
$error-callout-title-color--large: color(secondary);
$error-callout-subtitle-color: color(secondary);
$error-callout-subtitle-color--large: color(secondary);
$error-callout-max-width: rem-calc(471);
$error-callout-title-max-width: rem-calc(271);
$error-callout-title-max-width--large: rem-calc(371);
$error-callout-title-text-align: center;
$error-callout-title-text-align--large: center;
$error-callout-subtitle-text-align: center;
$error-callout-subtitle-text-align--large: center;
$error-callout-title-text-transform: lowercase;
$error-callout-subtitle-text-transform: lowercase;
$error-callout-title-margin: 0 auto rem-calc(15);
$error-callout-title-margin--large: 0 auto rem-calc(30);

@import "@lora/05-components/error-callout";