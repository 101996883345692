/* stylelint-disable */
@import '@root/05-components/checkout/checkout-shipping';

.c-modal .c-ngcheckout__storepickup {
    +.c-separator {
        display: none;

        +.c-ngcheckout-selection {
            display: none;
        }
    }

    &:has(.c-ngcheckout-selection__header) {
        +.c-separator {
            display: block;

            +.c-ngcheckout-selection {
                display: block;
            }
        }
    }
}