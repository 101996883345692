$content-gutters--large: rem-calc(20);
$content-secondary-background: color(global-background);
$content-main-top-border-bottom: none;
$content-title-text-transform: lowercase;
$content-title-font: normal #{rem-calc(26)} / #{rem-calc(30)} $font-secondary;
$content-title-font--large: $content-title-font;
$content-title-margin--large: rem-calc(20 0 30);
$content-subtitle-text-transform: lowercase;
$content-subtitle-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$content-subtitle-font--large: $content-subtitle-font;
$content-separate-title-text-transform: lowercase;
$content-description-font-size: rem-calc(14);
$content-description-font-weight: 300;
$content-description-color: color(text-secondary);
$content-description-text-transform: lowercase;
$content-sidebar-columns--large: 3 of 12;
$content-section-padding--large: rem-calc(0 20 30 0);
$content-centered-secondary-background: none;
$content-title-width--large: rem-calc(400);
$content-section-item-margin--large: rem-calc(0 (-$content-gutters--large) 20 0);

@import "@lora/04-layout/content";