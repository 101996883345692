$footer-background: color(dark);
$footer-background--large: color(dark);
$footer-top-background: color(primary-active);
$footer-bottom-border-top: rem-calc(1) solid color(light);
$footer-bottom-border-top--large: rem-calc(1) solid color(border);
$footer-newsletter-margin: rem-calc(28 0 0);
$footer-newsletter-margin--large: rem-calc(37 0 0);
$footer-social-margin: 0;
$footer-social-margin--large: rem-calc(20 -10 32 0);
$footer-newsletter-signup-title-display: block;
$footer-row-variants-flex-direction: column-reverse;
$footer-row-item-margin: rem-calc(0 0 10);
$footer-row-item-last-padding-left: 0;
$footer-row-variants-margin: rem-calc(15 0);
$footer-bottom-border-top: none;
$footer-bottom-border-top--large: rem-calc(1) solid color(border);
$footer-newsletter-captcha-text-font: 300 #{rem-calc(12)} / 1 $font-primary;
$footer-newsletter-captcha-link-font: normal #{rem-calc(12)} / 1 $font-primary;

@import "@lora/04-layout/footer";