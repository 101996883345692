// Title
$content-hero-title-font: #{rem-calc(26)} / #{rem-calc(30)} $font-secondary;
$content-hero-title-font--large: #{rem-calc(34)} / #{rem-calc(38)} $font-secondary;
$content-hero-title-text-transform: lowercase;
$content-hero-title-text-transform--large: $content-hero-title-text-transform;

// Description
$content-hero-description-font: 300 #{rem-calc(12)} / 1.5 $font-primary;
$content-hero-description-font--large: 300 #{rem-calc(14)} / 1.45 $font-primary;
$content-hero-description-color: color(text);
$content-hero-no-image-description-color--large: color(text-secondary);
$content-hero-description-text-transform: lowercase;
$content-hero-info-item-color--large: color(light);

// label
$content-hero-label-font: normal #{rem-calc(12)} / 1.25 $font-primary;
$content-hero-label-text-transform: lowercase;
$content-hero-label-text-decoration: underline;
$content-hero-label-padding: rem-calc(3 0 3 9);
$content-hero-label-border-left: 2px solid color(primary-active);
$content-hero-label-color: color(primary-active);
$content-hero-label-margin: rem-calc(0 0 20);
$content-hero-label-margin--large: rem-calc(0 0 40);

@import "@lora/05-components/content/content-hero";